import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private serviceUrl: string = environment.url;
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.url;
  }

  getCustomerList(): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Administration/GetCustomerList').pipe(
      catchError(this.handleError)
    );
  }

  getCustomerYears(customerId: number): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Administration/GetCustomerYears?customerId=' + customerId).pipe(
      catchError(this.handleError)
    );
  }
  getScaleAndCutSet(customerId: number,year:number): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Administration/GetScaleAndCutSet?customerId=' + customerId + '&year=' + year).pipe(
      catchError(this.handleError)
    );
  }
  getsubjectandgrades(customerId: number, year: any): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Administration/GetSubjectsandGrade?customerId=' + customerId + '&year=' + year).pipe(
      catchError(this.handleError)
    );
  }
  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }

  getAdministrationDetails(customerId: number, years: any[]): Observable<any> { 
    return this.http.get(this.serviceUrl+'/api/Administration/GetAdministrationDetails?customerId='+ customerId + '&years=' + years );
  }
  getAdministrationDetailsByCustomerID(customerId: number): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Analysis/getAdministrationDetails?customerId=' + customerId).pipe(
      catchError(this.handleError)
    );
  }
  getTestInstanceDetails(customerId: number, years: any[], administrationId: number,pagenumber:number,pagesize:number): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Administration/GetTestInstanceDetails?customerId=' + customerId + '&years=' + years + '&administrationId=' + administrationId + '&pagenumber=' + pagenumber + '&pagesize=' + pagesize);
  }
  saveAdministration(customerID: number, administrationName: string, subjectAndGradeList: any[], year: string, description: string): Observable<any> {
    const body = {
      customerID,
      administrationName,
      subjectAndGradeList,
      year,
      description
    };

    return this.http.post(`${this.serviceUrl}/api/Administration/SaveAdministration`, body);
  }

  getSubjectList(customerID: number, year: string): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Administration/GetSubjects?customerID=' + customerID + '&year=' + year);
  }
  getGradeList(customerID: number, year: string): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Administration/GetGrades?customerID=' + customerID + '&year=' + year);
  }
  getCustomerTestInstances(adminID: number, customerID: number, year: string, subject: string, grade: string, pgNbr: number, pageSize: number, filter?: string, breach?: boolean | null, accommadated?: boolean | null): Observable<any> {
    const params: any = { adminID, customerID, year, subject, grade, pgNbr, pgSize: pageSize, filter };
    if (breach != null) {
      params.breach = breach;
    } if (accommadated != null) {
      params.accommadated = accommadated
    }
    //return this.http.get(`${this.serviceUrl}/api/Administration/GetCustomerInstances?adminID=${adminID}&customerID=${customerID}&year=${year}&subject=${subject}&grade=${grade}&pgNbr=${pgNbr}&pgSize=${pageSize}&filter=${filter}&breach=${breach}&accommadated=${accommadated}`);
    return this.http.get(`${this.serviceUrl}/api/Administration/GetCustomerInstances`, { params });
  }
  SaveCustomerTestInstances(customerInstanceIDs: string, subject: string, grade: string,administrationID: number, years:string, customerID:number): Observable<any> {
    return this.http.post(`${this.serviceUrl}/api/Administration/SaveCustomerTestInstance?customerInstanceIDs=${customerInstanceIDs}&subject=${subject}&grade=${grade}&administrationID=${administrationID}&years=${years}&customerID=${customerID}`,null);
  }

  getanalysis(administrationId: number): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Analysis/getAnalysis?administrationID=' + administrationId).pipe(
      catchError(this.handleError)
    );
  }
  saveanalysis(administrationID: number, analysisName: string, dateStamp: any) {
    return this.http.post(this.serviceUrl + '/api/Analysis/SaveAnalysis?administrationID=' + administrationID + '&name=' + analysisName + '&datestamp=' + dateStamp,null)
  }

  updateScaleAndCutSet(yearOfRecord,scaleAndCutSet: any[]): Observable<any> {
    const body = {
      yearOfRecord: yearOfRecord,
      scalesAndCuts: scaleAndCutSet
    };
    return this.http.post(this.serviceUrl+'/api/Administration/UpdateScaleAndCutSet',body);
  }
  getAnalysisSpecification(administrationId: number, analysisid: number, pagenumber: number, pagesize: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetAnalysisSpecification?administrationId=${administrationId}&administrationAnalysisID=${analysisid}&pagenumber=${pagenumber}&pagesize=${pagesize}`);
  }
  getreportingcategoryList(): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Analysis/ReportingCategory').pipe(
      catchError(this.handleError)
    );
  }
  getSubjectsandGrades(customerId: number, years: any[], administrationID :any): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/Analysis/Getsubjectsandgrade?customerId=' + customerId + '&years=' + years + '&administrationId='+administrationID);
  }
  saveDuplicateAnalysis(duplicateAnalysis): Observable<any>{
    return this.http.post(this.serviceUrl+'/api/Analysis/saveDuplicateAnalysis',duplicateAnalysis);
  }
  getEditAnalysisSpecifications(purpose: string,filter?:string): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetAnalysisEditSpecifications?purpose=${purpose}&filter=${filter}`);
  }
  getEditAnalysisCTT(): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetEditAnalysisCTT`);
  }
  getEditAnalysisDIFType(): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetEditAnalysisDIFType`);
  }
  getDifReference(difID: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetDifReferences?difID=${difID}`);
  }
  saveDifAnalysis(difTypeID: number, refGroupID: number, focalGroupID: number, testAnalysisSetID: number): Observable<any>{
    return this.http.post(`${this.serviceUrl}/api/Analysis/SaveDifAnalysis?difTypeID=${difTypeID}&refGroupID=${refGroupID}&focalGroupID=${focalGroupID}&testAnalysisSetID=${testAnalysisSetID}`,null)
  }
  getInstanceExclusion(customerID:number,adminID: number, analysisID: number, year: string,subject:string,grade:string, searchText?: string) {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetInstanceExclusion?customerID=${customerID}&adminID=${adminID}&analysisID=${analysisID}&year=${year}&subject=${subject}&grade=${grade}&searchText=${searchText}`);
  }
  saveInstanceExclusion(customID: string, testInstanceSetAnalysisID: number,flag:boolean): Observable<any> {
    return this.http.post(`${this.serviceUrl}/api/Analysis/SaveInstanceExclusion?customerTestInstanceID=${customID}&testInstanceSetAnalysisID=${testInstanceSetAnalysisID}&flag=${flag}`,null);
  }
  getItemExclusion(customerID: number, adminID: number, analysisID: number, year: string, subject: string, grade: string, searchText?: string) {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetItemExclusion?customerID=${customerID}&adminID=${adminID}&analysisID=${analysisID}&year=${year}&subject=${subject}&grade=${grade}&searchText=${searchText}`);
  }
  saveItemExclusion(customerTestIDs: string, testInstanceSetAnalysisID: number, flag: boolean): Observable<any> {
    return this.http.post(`${this.serviceUrl}/api/Analysis/SaveItemExclusion?customerTestIDs=${customerTestIDs}&testInstanceSetAnalysisID=${testInstanceSetAnalysisID}&flag=${flag}`, null);
  }
  getStudentExclusion(testInstanceSetAnalysisID: number, pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetStudentExclusion?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }
  saveStudentExclusion(studentID: string, testInstanceSetAnalysisID: number, isSaveorDelete: boolean): Observable<any> {
    return this.http.post(`${this.serviceUrl}/api/Analysis/SaveStudentExclusion?studentID=${studentID}&testInstanceSetAnalysisID=${testInstanceSetAnalysisID}&isSaveorDelete=${isSaveorDelete}`, null);
  }
  saveEditSpecification(EditSpecification: object) {
    let specSet = EditSpecification;
    return this.http.post(`${this.serviceUrl}/api/Analysis/SaveEditAnalysisSpecification`, specSet);
  }
  getEditAnalysisSpecificationSet(testInstanceSetAnalysisID: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetEditAnalysisSpecificationSet?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}`);
  }
  getEditAnalysisDifData(testInstanceSetAnalysisID: number) {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetEditAnalysisDIFData?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}`);
  }
  deleteDifGroup(testInstanceSetAnalysisID: number, difGroupTypeID: number) {
    return this.http.delete(`${this.serviceUrl}/api/Analysis/DeleteDifGroup?difGroupTypeID=${difGroupTypeID}&testInstanceSetAnalysisID=${testInstanceSetAnalysisID}`);
  }

  saveDuplicateAnalysisSpecification(analysisSpecificationSet): Observable<any> {
    return this.http.post(this.serviceUrl+'/api/Analysis/saveDuplicateAnalysisSpecification',analysisSpecificationSet);
  }
  saveCopyAnalysisSpecification(copyAnalysisSpecificationSet): Observable<any> {
    return this.http.post(this.serviceUrl+'/api/Analysis/saveCopyAnalysisSpecification',copyAnalysisSpecificationSet);
  }
  getThetaCalulationMethod(searchText?: string): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/getThetaCalculationMethod?searchText=${searchText}`)
  }


  ////Perform Analysis
  getPerformAnalysisDetails(administrationAnalysisID: number, testInstanceSetAnalysisIDs: number[], pIsInitial: boolean, state: string): Observable<any> {
    const params = {
      administrationAnalysisID: administrationAnalysisID.toString(),
      pIsInitial: pIsInitial.toString(),
      state: state
    };
    const testInstanceParams = testInstanceSetAnalysisIDs.map(id => `testInstanceSetAnalysisIDs=${id}`).join('&');
    const url = `${this.serviceUrl}/api/Analysis/GetPerformAnalysisDetails?${new URLSearchParams(params as any)}&${testInstanceParams}`;
    return this.http.get(url);
  }

  getPerformAnalysisDetails1(administrationAnalysisID: number, testInstanceSetAnalysisIDs: number[], pIsInitial: boolean, state:string): Observable<any> {
    const params = {
      administrationAnalysisID: administrationAnalysisID.toString(),
      pIsInitial: pIsInitial.toString(),
      state: state
    };
    const testInstanceParams = testInstanceSetAnalysisIDs.map(id => `testInstanceSetAnalysisIDs=${id}`).join('&');
    const url = `${this.serviceUrl}/api/Analysis/GetPerformAnalysisDetails1?${new URLSearchParams(params as any)}&${testInstanceParams}`;
    return this.http.get(url);
  }
  getTestInstanceSetAnalysisDetails(administrationAnalysisID: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetTestInstanceSetAnalysisDetails?administrationAnalysisID=${administrationAnalysisID}`);
  }
 
  getInitialCTTDetails(testInstanceSetAnalysisID: number, contentArea: string, testGrade: string, pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetInitialCTTDetails?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}&contentArea=${contentArea}&testGrade=${testGrade}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }
  getInitialCTTResponse(testInstanceSetAnalysisID: number, itemID: number, fromInitial: boolean): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetInitialCTTResponse?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}&itemID=${itemID}&fromInitial=${fromInitial}`);
  }
  getDifDetails(testInstanceSetAnalysisID: number, pgNbr: number, pgeSize: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetDifDetails?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}&pagenumber=${pgNbr}&pagesize=${pgeSize}`);
  }
  getCEFit(testInstanceSetAnalysisID: number, contentArea: string, testGrade: string, pageNumber: number, pageSize: number,isFromCalibrate:boolean): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetCalibrationOREquatingFITData?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}&contentArea=${contentArea}&testGrade=${testGrade}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }
  saveItemIntervention(interventions: Object) {
    return this.http.post(`${this.serviceUrl}/api/Analysis/SaveItemInterventions`, interventions);
  }
  getAnalysisCalibrate(administrationAnalysisID: number, testInstanceSetAnalysisIDs: number[]): Observable<any> {
    const params = {
      administrationAnalysisID: administrationAnalysisID.toString()
    };
    const testInstanceParams = testInstanceSetAnalysisIDs.map(id => `testInstanceSetAnalysisIDs=${id}`).join('&');

    const url = `${this.serviceUrl}/api/Analysis/GetAnalysisCalibrate?${new URLSearchParams(params as any)}&${testInstanceParams}`;
    return this.http.get(url);
  }
  getAnalysisDIF(administrationAnalysisID: number, testInstanceSetAnalysisIDs: number[]): Observable<any> {
    const params = {
      administrationAnalysisID: administrationAnalysisID.toString()
    };
    const testInstanceParams = testInstanceSetAnalysisIDs.map(id => `testInstanceSetAnalysisIDs=${id}`).join('&');

    const url = `${this.serviceUrl}/api/Analysis/GetAnalysisDIF?${new URLSearchParams(params as any)}&${testInstanceParams}`;
    return this.http.get(url);
  }
  getAnalysisDrift(administrationAnalysisID: number, testInstanceSetAnalysisIDs: number[]): Observable<any> {
    const params = {
      administrationAnalysisID: administrationAnalysisID.toString()
    };
    const testInstanceParams = testInstanceSetAnalysisIDs.map(id => `testInstanceSetAnalysisIDs=${id}`).join('&');

    const url = `${this.serviceUrl}/api/Analysis/GetAnalysisDrift?${new URLSearchParams(params as any)}&${testInstanceParams}`;
    return this.http.get(url);
  }
  getAnalysisEquating(administrationAnalysisID: number, testInstanceSetAnalysisIDs: number[]): Observable<any> {
    const params = {
      administrationAnalysisID: administrationAnalysisID.toString()
    };
    const testInstanceParams = testInstanceSetAnalysisIDs.map(id => `testInstanceSetAnalysisIDs=${id}`).join('&');

    const url = `${this.serviceUrl}/api/Analysis/GetAnalysisEquating?${new URLSearchParams(params as any)}&${testInstanceParams}`;
    return this.http.get(url);
  }
  GetDriftGraphAnalysisDetails(testInstanceSetAnalysisID: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetDriftGraphAnalysisDetails?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}`);
  }
  GetEquatingItemDriftAnalysis(testInstanceSetAnalysisID: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetEquatingItemDriftAnalysis?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}`);
  }
  GetEquatingItemSumarryDriftDetails(testInstanceSetAnalysisID: number): Observable<any> {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetEquatingItemSumarryDriftDetails?testInstanceSetAnalysisID=${testInstanceSetAnalysisID}`);
  }
  getCutScoreInformation(contentArea: string, testGrade: string, itemId: number, testInstanceSetAnalysisID: number, analysisID: number) {
    return this.http.get(`${this.serviceUrl}/api/Analysis/GetCutScoreInformation?contentArea=${contentArea}&testGrade=${testGrade}&itemId=${itemId}&testInstanceSetAnalysisID=${testInstanceSetAnalysisID}&analysisID=${analysisID}`);
  }
  UpdateItemDriftDetails(intervention: any[], testInstanceSetAnalysisID: number,) {
    const body = {
      intervention: intervention,
      testInstanceSetAnalysisID: testInstanceSetAnalysisID
    };
    return this.http.post(`${this.serviceUrl}/api/Analysis/UpdateItemDriftDetails`, body);
  }
 
  
  ////-------------
}
