<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<!--<div class="user-profile">-->
    <!-- User profile image -->
    <!--<div class="profile-img">
        <img src="assets/images/user_logo.png" alt="user"> </div>-->
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
        <!--<h2 class="user_name">Test User</h2>
   
</div>-->

<!--<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected" 
    group="{{menuitem.state}}" (click)="scrollToTop()">
        <a class="" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a class="" appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a class="" appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a class="" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'" >
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index" routerLinkActive="selected">
                <a [routerLink]="['/', menuitem.state, childitem.state ]"  *ngIf="childitem.type === 'link'" class="relative"
                    routerLinkActive="selected" >{{ childitem.name | translate}}</a>
            </mat-list-item>
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index">
                <a class="" href="javascript: void(0);" *ngIf="childitem.type === 'subchild'"
                    >
                    <span>{{ childitem.name | translate }}</span>
                    <span fxFlex></span>
                    <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
                </a>
                <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'" >
                    <mat-list-item *ngFor="let child of childitem['subchildren']" routerLinkActive="selected">
                        <a [routerLink]="['/', menuitem.state, childitem.state, child.state ]" routerLinkActive="selected" >{{child.name | translate}}</a>
                    </mat-list-item>
                </mat-nav-list>
            </mat-list-item>

        </mat-nav-list>

        <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
            <span>{{ menuitem.name | translate }}</span>
        </div>
    </mat-list-item>
</mat-nav-list>-->

<mat-nav-list>

  @for(menuitem of menuItems.getMenuitem();track $index){
  <div class="menu_item_div">
    <a mat-list-item class="menu-item"
          (click)="toggleNested(menuitem,isMenuCollapsed)">
      <mat-icon matListItemIcon>{{ menuitem.icon }}</mat-icon>

      <span matListItemTitle>
        {{ menuitem.name | translate }}
        @if(menuitem.children && !isMenuCollapsed){
        <span matListItemMeta>
          <span fxflex=""></span>
          <mat-icon [style]="expandMenuItem == menuitem.name ? 'transform: rotate(-180deg)':''">keyboard_arrow_down</mat-icon>
        </span>
        }
      </span>

    </a>
    <!--@if(isMenuCollapsed){-->
    <div class="sub-item">
      @for(subMenu of menuitem.children; track $index){
      <a mat-list-item routerLinkActive="selected" [routerLink]="['/', menuitem.state, subMenu.state ]" *ngIf="subMenu.type === 'link'"
         #rla="routerLinkActive" [activated]="rla.isActive" [style]="expandMenuItem == menuitem.name ? 'display:block':'display:none'">
        <span matListItemTitle>{{ subMenu.name | translate }}</span>
      </a>
      }
    </div>

    <!--}-->
  </div>
    }
 
</mat-nav-list>
